// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-franchise-jsx": () => import("./../../../src/pages/franchise.jsx" /* webpackChunkName: "component---src-pages-franchise-jsx" */),
  "component---src-pages-giftcards-jsx": () => import("./../../../src/pages/giftcards.jsx" /* webpackChunkName: "component---src-pages-giftcards-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-order-jsx": () => import("./../../../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-rewards-jsx": () => import("./../../../src/pages/rewards.jsx" /* webpackChunkName: "component---src-pages-rewards-jsx" */),
  "component---src-pages-social-gallery-jsx": () => import("./../../../src/pages/social-gallery.jsx" /* webpackChunkName: "component---src-pages-social-gallery-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

